$width: 1366px;
$blue: #2F80ED;
$yellow: #F5D130;

.box {
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    display: inline-block;
    width: 0.1px;
    height: 0;
    padding-bottom: 100%;
    vertical-align: middle;
  }
  .box-content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
  }
}

.app-wrapper {
  position: relative;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  padding-top: 100px;
}

.app-header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background-color: rgba($color: #fff, $alpha: 0.9);
  .header-content {
    width: $width;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .header-logo {
    flex: 1;
    height: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto 52px;
    position: relative;
    top: -8px;
  }
  .header-tab {
    display: flex;
    position: relative;
    right: -20px;
    font-size: 16px;
    .tab-item {
      padding: 10px 20px;
      cursor: pointer;
      transition: color 0.3s;
      &.active {
        color: $blue;
        font-weight: 700;
      }
      &:hover {
        color: lighten($blue, 5%);
      }
    }
  }
}

.app-block {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .block-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    font-family: 'Alimama ShuHeiTi', 'PingFang SC';
  }
  .block-content {
    width: $width;
    overflow: hidden;
    position: relative;
    z-index: 10;
  }
  .block-bg {
    position: absolute;
    left: 50%;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: $yellow;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    transform: translateX(-50%) scale(2.55);
    transform-origin: top center;
  }
  .block-watermark {
    position: absolute;
    height: 759px;
    left: 50%
  }
}

.app-block1 {
  @extend .box;
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
  &::before {
    padding-bottom: 37.77%;
  }
  .block-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .block-title {
    color: $blue;
    font-size: 64px;
    text-align: left;
    font-family: 'Alimama ShuHeiTi', 'PingFang SC';
  }
  .block-subtitle {
    font-weight: 700;
    font-size: 64px;
    line-height: 1.2;
    font-family: 'Alimama ShuHeiTi', 'PingFang SC';
  }
  .block-description {
    width: 350px;
    height: 67px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    color: #fff;
    font-weight: 600;
    font-size: 36px;
    margin-top: 34px;
  }
}

.app-block2 {
  flex-direction: column;
  align-items: center;
  padding-top: 58px;
  .block-watermark {
    bottom: 200px;
    transform: translateX(calc(-50% + 170px));
  }
  .block-desc {
    font-size: 16px;
    line-height: 2;
    text-align: center;
    margin-top: 26px;
    padding-bottom: 26px;
  }
  .block-swipe1 {
    position: relative;
    width: 100%;
    padding: 114px 0 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .swipe1-title {
      position: relative;
      z-index: 10;
      font-size: 36px;
      font-weight: 600;
      line-height: 1.4;
    }
    .swipe1-tab {
      display: flex;
      position: relative;
      z-index: 10;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
      color: #828282;
      margin-top: 28px;
      .tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 220px;
        margin: 0 104px;
        cursor: pointer;
        .item-text {
          display: flex;
          align-items: center;
          height: 58px;
          transition: color 0.3s;
          position: relative;
        }
        &:hover .item-text {
          color: lighten($color: #000000, $amount: 30%);
        }
        &.active .item-text {
          color: #333;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 8px;
            background-color: $blue;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
    .swipe1-images {
      width: $width;
      border-radius: 32px;
      overflow: hidden;
      margin-top: 60px;
      position: relative;
      z-index: 10;
      .swiper-button-prev, .swiper-button-next {
        color: $blue;
        font-weight: bolder;
        &::after {
          font-size: 30px;
        }
      }
      .swiper-button-prev {
        left: 50px;
      }
      .swiper-button-next {
        right: 50px;
      }
      .swiper-pagination {
        bottom: 0;
        .swiper-pagination-bullet {
          width: 12px;
          height: 12px;
        }
        .swiper-pagination-bullet-active {
          background-color: #F2C94C;
        }
      }
    }
  }
  .block-swipe2 {
    width: $width;
    padding-top: 72px;
    .swipe2-content {
      display: flex;
      align-items: center;
      position: relative;
      .content-left {
        position: relative;
        flex: 1;
        padding-bottom: 110px;
        .left-title {
          font-size: 36px;
          font-weight: 600;
          line-height: 1.4;
          padding: 20px 0;
          font-family: 'Roboto', 'PingFang SC';
        }
        .left-text {
          display: flex;
          align-items: center;
          line-height: 1.4;
          font-size: 24px;
          margin: 16px 0;
          transition: color 0.3s;
          cursor: pointer;
          position: relative;
          span {
            display: block;
            min-width: 50px;
            font-size: 36px;
            font-weight: 800;
            font-style: italic;
            color: #E0E0E0;
            margin-right: 40px;
            transition: color 0.3s;
          }
          &.active {
            font-weight: 600;
            span {
              color: #333;
            }
            &::after {
              content: '';
              position: absolute;
              height: 2px;
              width: 200px;
              background-color: $blue;
              top: 50%;
              left: 330px;
              transform: translateY(-50%);
            }
          }
        }
      }
      .content-right {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        overflow: hidden;
        left: 29px;
        &.padding {
          padding-bottom: 70px;
        }
        .right-image {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden;
        }
      }
      .content-active {
        position: absolute;
        left: 638px;
        bottom: 20px;
        z-index: 10;
        line-height: 1.4;
        color: #000;
        .active-title {
          display: flex;
          font-size: 32px;
          font-weight: 600;
          .title-content {
            position: relative;
            &::after {
              content: '';
              position: absolute;
              width: calc(100% + 2px);
              height: 50%;
              background-color: $yellow;
              left: -1px;
              bottom: 0px;
              z-index: 1;
            }
          }
          span {
            position: relative;
            z-index: 10;
          }
        }
        .active-desc {
          font-size: 24px;
          margin-top: 30px;
        }
      }
    }
    .swipe2-footer {
      position: relative;
      display: flex;
      justify-content: center;
      padding-top: 60px;
      margin-bottom: 73px;
      .footer-dots {
        position: relative;
        display: flex;
        &::after, &::before {
          content: '';
          position: absolute;
          height: 2px;
          background-color: #E0E0E0;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          width: 522px;
          right: calc(100% + 15px);
        }
        &::after {
          width: 300px;
          left: calc(100% + 15px);
        }
        .dots-item {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #D9D9D9;
          transition: background-color 0.15s;
          margin: 0 6px;
          position: relative;
          &.active {
            background-color: #F2C94C;
          }
          &:first-child:before {
            content: '';
            position: absolute;
            width: 2px;
            height: 104px;
            background-color: #E0E0E0;
            bottom: calc(100% - 6px);
            left: -543px;
          }
        }
      }
      .footer-switch {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        transform: translateY(-26px);
        .switch-btn {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          box-shadow: 0px 4px 12px 0px #00000040;
          background-color: #fff;
          cursor: pointer;
          &:not(:first-child) {
            margin-left: 97px;
            .btn-icon {
              transform: rotate(180deg);
            }
          }
          .btn-icon {
            width: 44px;
            height: 44px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }
}

.app-block3 {
  padding: 60px 0 82px;
  .block-watermark {
    bottom: -90px;
    transform: translateX(calc(-50% - 200px));
  }
  .block-desc {
    font-size: 16px;
    line-height: 2;
    text-align: center;
    margin-top: 26px;
  }
  .block-list {
    padding-top: 36px;
    .list-item {
      display: flex;
      align-items: center;
      margin-top: 40px;
      &:nth-child(even) {
        flex-direction: row-reverse;
        .item-images {
          flex: none;
          img {
            height: auto;
            user-select: none;
          }
        }
        .item-info {
          flex: 1;
        }
      }
      .item-images {
        flex: 1;
        display: flex;
      }
      .item-info {
        min-width: 275px;
        .info-title {
          display: flex;
          margin-bottom: 28px;
          .title-content {
            position: relative;
            font-size: 24px;
            font-weight: 600;
            line-height: 1;
            &::after {
              content: '';
              position: absolute;
              width: calc(100% + 2px);
              height: calc(50% + 4px);
              background-color: $yellow;
              left: -1px;
              bottom: -2px;
              z-index: 1;
            }
            span {
              position: relative;
              z-index: 10;
            }
          }
        }
        .info-text {
          font-size: 16px;
          line-height: 2;
          color: #000;
          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.app-block4 {
  padding-top: 60px;
  .block-content {
    overflow: visible;
  }
  .block-info {
    display: flex;
    margin: 50px 0 86px 0;
    flex-wrap: wrap;
    .info-item {
      flex: 1;
      background: #fff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 22px;
      margin: 30px 0 0 30px;
      min-width: 20%;
      position: relative;
      overflow: hidden;
      &::before {
        padding-bottom: 135%;
      }
      &::after {
        content: '';
        position: absolute;
        width: 150%;
        height: 100%;
        left: 50%;
        bottom: 56%;
        transform: translateX(-50%);
        background-color: #E0E0E0;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 100%;
      }
      &:nth-child(4n +1) {
        margin-left: 0;
      }
      .box-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;
      }
      .item-icon {
        width: 68px;
        height: 68px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .item-desc {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 24%;
        .desc-text {
          margin: 10px 0;
        }
      }
      .item-dot {
        display: flex;
        span {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: #D9D9D9;
          margin: 0 8px;
        }
      }
    }
  }
}

.app-block5 {
  .block-content {
    position: relative;
    padding: 86px 0;
  }
  .block-info {
    display: flex;
    margin-top: 66px;
    .info-item {
      flex: 1;
      background-color: #fff;
      border-radius: 22px;
      box-shadow: 0px 4px 10px 0px #00000026;
      &::before {
        padding-bottom: 59.2%;
      }
      &:not(:first-child) {
        margin-left: 30px;
      }
      .box-content {
        padding: 30px 30px 0 30px;
      }
      .item-title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
      }
      .item-icon {
        width: 38px;
        height: 38px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        margin-right: 8px;
      }
      .item-desc {
        font-size: 16px;
        margin-top: 24px;
        line-height: 1.4;
        .desc-text {
          position: relative;
          padding-left: 48px;
          &:not(:first-child) {
            margin-top: 10px;
          }
          &::before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: #333;
            border-radius: 50%;
            top: 8px;
            left: 30px;
          }
        }
      }
    }
  }
}

.app-block6 {
  background-color: $yellow;
  padding: 60px 0 138px;
  .block-image {
    display: flex;
    margin-top: 66px;
    overflow: hidden;
    .image-item {
      flex: 1;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 22px;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
  .block-desc {
    font-size: 20px;
    line-height: 2;
    margin-top: 28px;
  }
}

.app-footer {
  overflow: hidden;
  .footer-top {
    height: 134px;
    display: flex;
    justify-content: center;
    background-color: $blue;
    overflow: hidden;
  }
  .footer-content {
    width: $width;
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .footer-logo {
    flex: 1;
    height: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto 52px;
  }
  .footer-info {
    font-size: 16px;
    line-height: 1.4;
    .info-title {
      font-weight: 600;
      margin-bottom: 12px;
      text-decoration: underline;
    }
    .info-item:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  .footer-text {
    height: 58px;
    display: flex;
    line-height: 1;
    align-items: center;
    justify-content: center;
    color: #707070;
    font-size: 14px;
    a {
      color: inherit;
      text-decoration: none;
      margin-left: 20px;
    }
  }
}

.arc-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .arc-top {
    width: 100%;
    height: 140px;
    position: relative;
    overflow: hidden;
    .top-content {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 730%;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
      transform: translateX(-50%) scale(2);
      transform-origin: top center;
    }
  }
  .arc-bottom {
    flex: 1;
  }
}
