* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  min-width: 1400px;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #333;
}

::-webkit-scrollbar{
  width: 0px;
}